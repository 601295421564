<template>
    <span style="font-size:  1.1rem; color: var(--blue-400);">
        <font-awesome-icon icon="edit" class="icon-button" @click="toAtualizar()"/>
    </span>
</template>

<script>
export default {
    props: {
        objeto: {
            type: Object,
        },
    },

    methods: {
        toAtualizar() {
            this.$emit('onEditar', this.objeto);
        },
    },
};
</script>
