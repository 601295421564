<template>
    <tabela headerStyle="width: 3em" :stateKey="idTabela" :salvarEstado="false" :data="data">
        <template #checkbox >
            <Checkbox name="Mostrar Inativos" class="ml-3 mb-2 align-content-start lg" :modelValue="inativos" @click="mostrarInativos" :binary="true" />
            <label for="binary"> Mostrar Inativos </label>
        </template>
        <template #botoes>
                <btn-inserir @onInserir="inserir"></btn-inserir>
        </template>
        <template #conteudo>
            <Column headerStyle="width: 70px">
                <template #body="slotProps">
                    <btn-atualizar title="Editar" class="flex align-items-center justify-content-center" @onEditar="editar" :objeto="slotProps.data"></btn-atualizar>
                </template>
            </Column>
             <template v-for="(campo, index) in campos" :key="index">
                <Column
                    :header="campo.titulo"
                    :field="campo.campo"
                    :sortable="true"
                    v-if="!campo.campoModified"
                ></Column>
                <Column v-if="campo.campoModified" :field="campo.campoModified" :header="campo.titulo" :sortable="true">
                    <template #body="slotProps">
                        {{ $dateFormat(slotProps.data.modified, 'DD/MM/YYYY HH:mm:ss') }}
                    </template>
                </Column>
             </template>
            <Column header="Status">
                <template #body="slotProps">
                    <status :status="formatar(slotProps.data)"></status>
                </template>
            </Column>
        </template>
    </tabela>
</template>
<script>
import BtnInserir from './BtnInserir.vue';
import BtnAtualizar from './BtnAtualizar.vue';

export default {
    components: {
        BtnInserir,
        BtnAtualizar
    },

    name: 'TableComponent',
    props: {
        data: {
            type: Array,
        },
        campos: {
            type: Array,
        },
        inativos: {
            type: Boolean
        },
        idTabela: {
            type: String
        }
    },
    methods: {
        editar(objeto) {
            this.$emit('onEditar', objeto);
        },
        inserir() {
            this.$emit('onInserir');
        },
        ativar(objeto) {
            this.$emit('onAtivar', objeto);
        },
        inativar(objeto) {
            this.$emit('onInativar', objeto);
        },
        mostrarInativos() {
           this.inativos? this.$emit('mostrarInativos', false) : this.$emit('mostrarInativos', true);
        },
        formatar(data) {
            return data.active == true ? 'Ativo' : 'Inativo';
        },
    },
};
</script>
